<script setup>
import { useNuxtApp } from '#imports';

const { $tp } = useNuxtApp();

const props = defineProps({
  heading: {
    type: String,
    default: undefined,
  },
  hint: {
    type: String,
    default: undefined,
  },
  text: {
    type: String,
    default: undefined,
  },
  description: {
    type: String,
    default: undefined,
  },
  theme: {
    type: String,
    default: undefined,
    validator(theme) {
      return [''].includes(theme);
    },
  },
});
</script>
<template>
  <div
    class="form-wrapper"
    :class="{
      [`form-wrapper--theme--${props.theme}`]: props.theme,
    }"
  >
    <div class="form-wrapper__heading">
      <div v-if="$slots.heading">
        <slot name="heading" />
      </div>

      <h3
        v-if="props.heading"
        class="form-wrapper__font form-wrapper__font--heading"
        v-html="$tp(props.heading)"
      />

      <div v-if="props.text" class="form-wrapper__text">
        <h4
          class="form-wrapper__font form-wrapper__font--text"
          v-html="$tp(props.text)"
        />
      </div>
    </div>

    <div v-if="props.description" class="form-wrapper__description">
      <p
        class="form-wrapper__font form-wrapper__font--description"
        v-html="$tp(props.description)"
      />
    </div>

    <div v-if="props.hint" class="form-wrapper__hint">
      <p
        class="form-wrapper__font form-wrapper__font--hint"
        v-html="$tp(props.hint)"
      />
    </div>

    <div class="form-wrapper__content">
      <slot name="content" />
    </div>
  </div>
</template>
<style scoped lang="scss">
.form-wrapper {
  $parent: &;

  background-color: $color-white;
  border-radius: em(48);

  @include media-breakpoint-down(sm) {
    border-radius: em(32) em(32) 0 0;
  }

  &__font {
    &--heading {
      font-size: em(30);
      font-weight: 700;
      line-height: 1;
      letter-spacing: -0.03em;
    }

    &--hint {
      font-size: em(13);
      line-height: 1.2;
    }

    &--description {
      font-size: em(18);
      line-height: 1.3;

      &:deep(a) {
        font-weight: 700;
        white-space: nowrap;
      }

      &:deep(strong) {
        font-weight: 700;
        white-space: nowrap;
      }
    }

    &--text {
      font-size: em(13);
      line-height: 1.2;
    }
  }

  &__heading {
    display: flex;
    flex-direction: column;
    gap: em(8);
    padding: em(36) em(88) em(36) em(36);
    background-color: $color-grey-1;
    border-bottom: 1px solid rgba($color-black, 0.1);
    border-radius: em(48);

    @include media-breakpoint-down(sm) {
      padding: em(32) em(76) em(32) em(24);
      border-radius: em(32);
    }
  }

  &__description {
    padding: 0 em(36);
    margin-top: em(36);

    @include media-breakpoint-down(sm) {
      padding: 0 em(24);
      margin-top: em(24);
    }
  }

  &__hint {
    padding: 0 em(36);
    margin-top: em(8);
    color: $color-grey-3;

    @include media-breakpoint-down(sm) {
      padding: 0 em(24);
    }
  }

  &__text {
    color: $color-grey-3;
  }

  &__content {
    padding: em(36);

    @include media-breakpoint-down(sm) {
      padding: em(24);
    }
  }
}
</style>
